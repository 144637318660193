import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { WppAvatar, WppPill } from '@platform-ui-kit/components-library-react'

import styles from 'app/components/form/formField/formFieldAutoComplete/FormFieldAutoComplete.module.scss'
import IOption from 'interfaces/IOption'

interface SelectedValuesComponent {
  values: AutocompleteDefaultOption[]
  onCloseClick: (a: number | string) => void
  disabled: boolean
  showAvatar: boolean
}

const SelectedValues = ({ values, onCloseClick, disabled, showAvatar }: SelectedValuesComponent) => (
  <div slot="selected-values" className={styles.selectedValues}>
    {values.map((option: IOption) => (
      <WppPill
        label={option.displayLabel}
        removable
        value={option.id}
        onWppClose={() => onCloseClick(option.id)}
        type="display"
        className={styles.pill}
        key={option.id}
        id={`pile-${option.id}`}
        name={option.displayLabel}
        disabled={disabled}
      >
        {showAvatar && <WppAvatar name={option.label} src={option.avatarUrl} slot="icon-start" />}
      </WppPill>
    ))}
  </div>
)

export default SelectedValues
