import _ from 'lodash'

import { FieldType, Orientation } from 'config/enums'
import IField from 'interfaces/field/IField'
import SharedHelper from 'utils/SharedHelper'

export default class FormFieldValidation {
  private sharedHelper: SharedHelper
  constructor() {
    this.sharedHelper = new SharedHelper()
  }
  /**
   * Validate a field
   * @param {IField} field
   * @param {any} value Updated value of the field
   * @returns {boolean}
   */
  validate(field: IField, value: any): boolean {
    let isValid = true

    if (_.isEqual(field.type, FieldType.TEXT_AREA)) {
      isValid = this.validateCharacterLimit(field, value)
    }
    return isValid
  }

  /**
   * Validate Character limit
   * @param {IField} field
   * @param {any} value
   * @returns {boolean}
   */
  validateCharacterLimit(field: IField, value: any): boolean {
    if (!value) {
      return true
    }

    if (!field.fieldConfig.charactersLimit) {
      return true
    }

    return _.lte(value.length, field.fieldConfig.charactersLimit)
  }

  /**
   * Validate field configuration
   * @param {IField} field
   * @returns {IField} field
   */
  validateFieldConfiguration(field: IField): IField {
    let fieldData: IField = _.cloneDeep(field)
    const { fieldConfig } = fieldData
    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.DATE_PICKER: {
        fieldData.value = _.isString(fieldData.value) ? fieldData.value : ''
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        fieldData.value = _.isArray(fieldData.value) ? fieldData.value : []
        fieldData.fieldConfig.options = _.isArray(fieldConfig.options) ? fieldData.fieldConfig.options : []
        break
      }
      case FieldType.FILE_UPLOAD: {
        fieldData.value = _.isArray(fieldData.value) ? fieldData.value : []
        break
      }
    }

    fieldData.fieldConfig.orientation = !fieldConfig.orientation ? Orientation.VERTICAL : fieldConfig.orientation

    return fieldData
  }

  /**
   * Validate if field is empty
   * @param {IField} field
   * @returns {boolean} field
   */
  isEmptyField(field: IField): boolean {
    let isFieldEmpty: boolean = false

    const Value = field.value || ''

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.DATE_PICKER:
      case FieldType.RADIO:
      case FieldType.TEXT_AREA: {
        isFieldEmpty = _.isEmpty(_.toString(Value).trim())
        break
      }
      case FieldType.SELECT: {
        if (field.fieldConfig.multi) {
          isFieldEmpty = _.isEmpty(Value)
        } else {
          isFieldEmpty = _.isEmpty(_.toString(Value))
        }
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE:
      case FieldType.FILE_UPLOAD: {
        isFieldEmpty = _.isArray(field.value) ? _.isEmpty(field.value) : false
        break
      }
    }

    return isFieldEmpty
  }
}
