// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z2140{color:#4d5358}.z2140::part(typography){font-size:.875rem}.TM4dj::part(typography){font-size:.875rem}.xERW8{display:flex;align-items:center;padding-bottom:.5rem;padding-top:1.5rem}.xERW8 .Tc871{position:absolute;display:inline}.GpZIc{padding:0;margin-left:.5rem;cursor:pointer}.mTohb{height:1rem}.yB7LR{margin-bottom:.5rem;width:100%}.yB7LR * a{text-decoration:underline !important}.yB7LR * i{font-style:italic}.yB7LR * b{font-weight:500}.M5z0i{color:#a9a9a9;cursor:default}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldLabel/FormFieldLabel.module.scss"],"names":[],"mappings":"AAAA,OAIE,aAAA,CAHA,yBACE,iBAAA,CAMF,yBACE,iBAAA,CAIJ,OACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,kBAAA,CAEA,cACE,iBAAA,CACA,cAAA,CAIJ,OACE,SAAA,CACA,iBAAA,CACA,cAAA,CAGF,OACE,WAAA,CAEF,OACE,mBAAA,CACA,UAAA,CACA,WACE,oCAAA,CAEF,WACE,iBAAA,CAEF,WACE,eAAA,CAIJ,OACE,aAAA,CACA,cAAA","sourcesContent":[".label {\n  &::part(typography) {\n    font-size: 0.875rem;\n  }\n  color: #4d5358;\n}\n\n.bodyLabel {\n  &::part(typography) {\n    font-size: 0.875rem;\n  }\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  padding-bottom: 0.5rem;\n  padding-top: 1.5rem;\n\n  .tooltip {\n    position: absolute;\n    display: inline;\n  }\n}\n\n.bodyContainer {\n  padding: 0;\n  margin-left: 0.5rem;\n  cursor: pointer;\n}\n\n.emptyLabel {\n  height: 1rem;\n}\n.description {\n  margin-bottom: 0.5rem;\n  width: 100%;\n  & * a {\n    text-decoration: underline !important;\n  }\n  & * i {\n    font-style: italic;\n  }\n  & * b {\n    font-weight: 500;\n  }\n}\n\n.disabled {\n  color: #a9a9a9;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `z2140`,
	"bodyLabel": `TM4dj`,
	"container": `xERW8`,
	"tooltip": `Tc871`,
	"bodyContainer": `GpZIc`,
	"emptyLabel": `mTohb`,
	"description": `yB7LR`,
	"disabled": `M5z0i`
};
export default ___CSS_LOADER_EXPORT___;
