import _ from 'lodash'

import { OrderBy, FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFieldAutoComplete from 'interfaces/field/IFieldAutoComplete'
import IResponseApp from 'interfaces/field/response/IResponseApp'
import IResponseField from 'interfaces/field/response/IResponseField'
import IFileConfig from 'interfaces/file/IFileConfig'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IForm from 'interfaces/IForm'
import IOption from 'interfaces/IOption'
import IPermission from 'interfaces/IPermission'
import FormValidation from 'utils/form/FormValidation'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'
import PermissionHelper from 'utils/permission/PermissionHelper'
import SharedHelper from 'utils/SharedHelper'

export default class FormHelper {
  private formFieldHelper: FormFieldHelper
  private formValidation: FormValidation
  private formFieldValidation: FormFieldValidation
  private permissionHelper: PermissionHelper

  private sharedHelper: SharedHelper

  /**
   *  Creates an instance of FormHelper
   */
  constructor() {
    this.formFieldHelper = new FormFieldHelper()
    this.formValidation = new FormValidation()
    this.formFieldValidation = new FormFieldValidation()
    this.permissionHelper = new PermissionHelper()
    this.sharedHelper = new SharedHelper()
  }

  /**
   * Order fields
   * @param {any[]} data
   * @returns {any[]}
   */
  getOrderedData(data: any[], orderKey: string): any[] {
    return _.orderBy(data, [orderKey], OrderBy.ASC)
  }

  async buildField(
    field: IResponseField,
    accessToken: string,
    tenantId: string,
    projectId: string,
    isAppEditor: boolean,
  ): Promise<IField> {
    const Children: IResponseField[] = _.isArray(field.children) ? field.children : []

    let fileConfig: IFileConfig = {}

    if (_.isEqual(field.fieldType, FieldType.FILE_UPLOAD) && field.config && field.config.file) {
      const { accept, max_size, template_title, template_url } = field.config.file

      fileConfig = {
        maxSize: max_size,
        accept: accept,
        templateTitle: template_title,
        templateUrl: template_url,
      }
    }

    let autoComplete: IFieldAutoComplete | any = {}

    if (_.isEqual(field.fieldType, FieldType.AUTOCOMPLETE) && field.config?.autocomplete) {
      const { items_per_page, keys, order_by } = field.config?.autocomplete

      const { page, id, label, sub_label, avatar_url, display_label } = keys
      autoComplete = {
        keys: {
          page,
          id: id,
          label: label,
          subLabel: sub_label,
          avatarUrl: avatar_url,
          itemsPerPage: keys.items_per_page,
          displayLabel: display_label,
        },
        itemsPerPage: items_per_page,
        orderBy: order_by,
      }
    }

    const FieldValue = this.formFieldHelper.getValidValue(
      field,
      !field.defaultValue ? field.defaultValue : field.defaultValue.value,
    )

    let visibleOnValue = !!field.config ? field.config?.visible_on_value : []
    visibleOnValue = !_.isArray(visibleOnValue) ? [] : visibleOnValue.map((value: string) => _.toString(value))

    let children = []

    for (let child of Children) {
      children.push(await this.buildField(child, accessToken, tenantId, projectId, isAppEditor))
    }

    const Options: IOption[] = await this.sharedHelper.getSelectOptions(field, accessToken, tenantId)

    return this.formFieldValidation.validateFieldConfiguration({
      id: field.id,
      formFieldId: field.formFieldId,
      type: field.fieldType,
      value: FieldValue,
      initialValue: _.cloneDeep(FieldValue),
      displayOrder: field.displayOrder,
      fieldConfig: {
        text: field.label,
        description: field.description,
        help: !field.help ? '' : field.help.text || '',
        tooltip: field.tooltip,
        accessToken,
        tenantId,
        projectId,
        apiUrl: field.apiUrl,
        placeholder: field.placeholder,
        options: Options,
        orientation: field.orientation,
        charactersLimit: !!field.config ? field.config?.character_limit : undefined,
        multi: !!field.config ? field.config?.multi : false,
        file: fileConfig,
        helpModal: !!field.help ? field.help.help_model : undefined,

        autoComplete,
      },
      disabled: !isAppEditor,
      touched: false,
      isValid: true,
      errorMessage: '',

      visibleOn: field.visibleOn || null,
      visibleOnValue,
      children: this.getOrderedData(children, 'displayOrder'),
    })
  }

  /**
   * Default field configuration
   * @param {IField} field
   * @param {boolean} IsAppEditor
   * @param {string} accessToken
   * @returns {IField}
   */
  async defaultFieldConfiguration(
    field: IResponseField,
    IsAppEditor: boolean,
    accessToken: string,
    tenantId: string,
    projectId: string,
  ): Promise<IField> {
    return this.formFieldValidation.validateFieldConfiguration(
      await this.buildField(field, accessToken, tenantId, projectId, IsAppEditor),
    )
  }

  /**
   * Get application configuration
   * @param {IApp} app
   * @param {IPermission[]} permissions
   * @param {string} accessToken
   * @param {string} tenantId
   * @returns {IApp}
   */
  async getApp(
    app: IResponseApp,
    permissions: IPermission[],
    accessToken: string,
    tenantId: string,
    projectId: string,
  ): Promise<IApp> {
    const IsAppEditor = this.permissionHelper.hasAppEditor(permissions)

    let updatedCategories = []

    for (let category of app.categories) {
      let forms: IForm[] = []
      for (let form of category.forms) {
        let fields: IField[] = []
        for (let field of form.fields) {
          fields.push(await this.defaultFieldConfiguration(field, IsAppEditor, accessToken, tenantId, projectId))
        }

        forms.push({
          ...form,
          helpModal: !!form.help ? form.help.help_model : undefined,
          description: form.description,
          help: !form.help ? '' : form.help.text || '',
          tooltip: form.tooltip || '',
          fields: this.getOrderedData(fields, 'displayOrder'),
        })
      }

      updatedCategories.push({
        ...category,
        name: category.name,
        description: category.description,
        isValid: true,
        forms: this.getOrderedData(forms, 'displayOrder'),
      })
    }

    const App: IApp = {
      appName: app.name,
      isValid: true,
      isAppEditor: IsAppEditor,
      completionRate: {
        completed: 0,
        total: 0,
        percentage: 0,
      },
      categories: this.updateDependentFields(this.getOrderedData(updatedCategories, 'displayOrder')),
    }

    return {
      ...App,
      completionRate: this.formFieldHelper.getCompletionRate(App),
    }
  }

  /**
   * Update app
   * @param {IApp} app
   * @param {InputFieldChangeEvent} event
   * @returns {IApp}
   */
  updateApp(app: IApp, event: IFieldChangeEvent): IApp {
    let categories: ICategory[] = this.updateForms(app.categories, event)
    categories = this.formValidation.validateDependentField(categories, event)
    categories = this.formValidation.validateCategories(categories)
    const IsValidApp: boolean = this.formValidation.validateForms(categories)

    const App: IApp = {
      ...app,
      categories,
      isValid: IsValidApp,
    }

    return {
      ...App,
      completionRate: this.formFieldHelper.getCompletionRate(App),
    }
  }

  /**
   * Update forms
   * @param {ICategory[]} categories
   * @param {InputFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  updateForms(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return this.updateDependentFields(
      categories.map((category: ICategory) => ({
        ...category,
        forms: category.forms.map((form: IForm) => ({
          ...form,
          fields: form.fields.map((field: IField) => this.formFieldHelper.updateField(field, event)),
        })),
      })),
    )
  }

  /**
   * Update dependent field
   * @param {ICategory[]} categories
   * @returns {ICategory[]}
   */
  updateDependentFields(categories: ICategory[]) {
    return categories.map((category: ICategory) => ({
      ...category,
      forms: category.forms.map((form: IForm) => ({
        ...form,
        fields: form.fields.map((field: IField) => {
          const VisibleOn = field.visibleOn || null
          if (!!VisibleOn && !!field.visibleOnValue) {
            const Field: IField | null = this.formFieldHelper.findFieldByFormFieldId(categories, VisibleOn)

            return {
              ...field,
              isHidden: !Field ? false : this.formFieldHelper.isFieldHidden(Field, field),
            }
          }
          return field
        }),
      })),
    }))
  }

  /**
   * Pre-save check category for file upload
   * @param {ICategory} category
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<ICategory>}
   */
  async preSaveCheck(
    category: ICategory,
    accessToken: string,
    tenantId: string,
    tenantUrl: string,
    projectId: string,
  ): Promise<ICategory> {
    let forms: IForm[] = []
    for (let form of category.forms) {
      const Fields: IField[] = []
      for (let field of form.fields) {
        const Field: IField = await this.formFieldHelper.preSaveFieldCheck(
          field,
          accessToken,
          tenantId,
          tenantUrl,
          projectId,
        )
        const Children: IField[] = []
        for (let children of Field.children) {
          const ChildField: IField = await this.formFieldHelper.preSaveFieldCheck(
            children,
            accessToken,
            tenantId,
            tenantUrl,
            projectId,
          )
          Children.push(ChildField)
        }
        Field.children = Children
        Fields.push(Field)
      }

      forms.push({
        ...form,
        fields: Fields,
      })
    }
    return {
      ...category,
      forms,
    }
  }
}
