import { WppCheckbox } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldCheckbox/FormFieldCheckbox.module.scss'
import FormFieldChildren from 'app/components/form/formField/formFieldChildren'
import FormFieldLabel from 'app/components/form/formField/formFieldLabel'
import FormFieldMessage from 'app/components/form/formField/formFieldMessage'
import { LabelType, Orientation } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IOption from 'interfaces/IOption'

interface IFormFieldCheckboxProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create checkbox field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = ({
  field,
  handleChange,
}: IFormFieldCheckboxProps): React.ReactElement => {
  const { disabled, value } = field
  const { orientation, options } = field.fieldConfig
  const Options = value.map((option: IOption) => option.id)

  const onChange = (option: IOption): void => {
    const Value = !_.isEqual(Options.indexOf(option.id), -1)
      ? value.filter((val: IOption) => !_.isEqual(val.id, option.id))
      : _.uniqBy([...value, option], 'id')

    handleChange({
      field,
      value: Value,
    })
  }

  return (
    <>
      <div
        className={classNames(
          {
            [styles.displayVertical]: _.isEqual(orientation, Orientation.VERTICAL),
            [styles.displayHorizontal]: _.isEqual(orientation, Orientation.HORIZONTAL),
          },
          styles.container,
        )}
      >
        {options.map((option: IOption, index: number) => (
          <div key={option.id}>
            <div
              style={{
                display: 'flex',
                marginTop: !_.isEqual(index, 0) && _.isEqual(orientation, Orientation.VERTICAL) ? 10 : 0,
                marginRight: _.isEqual(orientation, Orientation.HORIZONTAL) ? 10 : 0,
              }}
            >
              <WppCheckbox
                key={option.id}
                id={`checkbox-${field.id}-${index + 1}`}
                disabled={disabled}
                required={true}
                checked={!_.isEqual(Options.indexOf(option.id), -1)}
                onWppChange={() => onChange(option)}
              />
              <FormFieldLabel
                type={LabelType.BODY}
                id={`${field.id}-${option.id}`}
                text={option.label}
                helpModal={option.help_model}
                disabled={field.disabled}
                handleChange={() => onChange(option)}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              optionValue={option.id}
              handleChange={handleChange}
            />
          </div>
        ))}
      </div>
      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldCheckbox
