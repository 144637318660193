import React from 'react'

import FormFieldAutoComplete from 'app/components/form/formField/formFieldAutoComplete'
import FormFieldCheckbox from 'app/components/form/formField/formFieldCheckbox'
import FormFieldCounter from 'app/components/form/formField/formFieldCounter'
import FormFieldCurrency from 'app/components/form/formField/formFieldCurrency'
import FormFieldDatePicker from 'app/components/form/formField/formFieldDatePicker'
import FormFieldFileUpload from 'app/components/form/formField/formFieldFileUpload'
import FormFieldLabel from 'app/components/form/formField/formFieldLabel'
import FormFieldRadio from 'app/components/form/formField/formFieldRadio'
import FormFieldSelect from 'app/components/form/formField/formFieldSelect'
import FormFieldTextarea from 'app/components/form/formField/formFieldTextarea'
import FormFieldTextInput from 'app/components/form/formField/formFieldTextInput'
import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'

interface IFormFieldBuilderProps {
  /** IField object */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build form field
 * @param {object} props Component props
 * @param {IField} props.field field object
 * @param {function} props.handleChange handle input field change event
 */
const FormFieldBuilder: React.FC<IFormFieldBuilderProps> = (props: IFormFieldBuilderProps): React.ReactElement => {
  let fieldElement = <></>

  if (props.field.isHidden) {
    return fieldElement
  }

  const { fieldConfig } = props.field

  switch (props.field.type) {
    // Text Input Field
    case FieldType.TEXT_INPUT:
      fieldElement = <FormFieldTextInput field={props.field} handleChange={props.handleChange} inputType="text" />
      break
    // Radio Field
    case FieldType.RADIO:
      fieldElement = <FormFieldRadio {...props} />
      break
    // Checkbox Field
    case FieldType.CHECKBOX:
      fieldElement = <FormFieldCheckbox {...props} />
      break
    // Counter Field
    case FieldType.COUNTER:
      fieldElement = <FormFieldCounter {...props} />
      break
    // Textarea Field
    case FieldType.TEXT_AREA:
      fieldElement = <FormFieldTextarea {...props} />
      break
    // Autocomplete Field
    case FieldType.AUTOCOMPLETE:
      fieldElement = <FormFieldAutoComplete {...props} />
      break
    // File upload Field
    case FieldType.FILE_UPLOAD:
      fieldElement = <FormFieldFileUpload {...props} />
      break
    // Currency Field
    case FieldType.CURRENCY:
      fieldElement = <FormFieldCurrency {...props} />
      break
    // Select Field
    case FieldType.SELECT:
      fieldElement = <FormFieldSelect {...props} />
      break
    // DatePicker Field
    case FieldType.DATE_PICKER:
      fieldElement = <FormFieldDatePicker {...props} />
      break
  }
  return (
    <>
      <FormFieldLabel
        id={props.field.id}
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={props.field.disabled}
        description={fieldConfig.description}
      />
      {fieldElement}
    </>
  )
}

export default FormFieldBuilder
