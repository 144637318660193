import { WppTypography } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldLabel/FormFieldLabel.module.scss'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import { LabelType } from 'config/enums'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldLabelProps {
  /** Field ID */
  id: string
  /** Label title */
  text: string
  /** Help Modal */
  helpModal?: IHelpModal
  /** If element is disabled */
  disabled: boolean
  /** handle input change event */
  handleChange?: () => void
  /** Tooltip */
  tooltip?: string
  /** Field description */
  description?: string
  /** label type - heading or body */
  type?: LabelType
}

/**
 * Create label for the field
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.text
 * @param {IHelpModal} props.helpModal
 * @param {boolean} props.disabled
 * @param {function} props.handleChange
 * @param {string} props.tooltip
 * @param {string} props.description
 * @param {LabelType} props.type
 */
const FormFieldLabel: React.FC<IFormFieldLabelProps> = (props: IFormFieldLabelProps): React.ReactElement => {
  const { id, text, disabled, helpModal, tooltip, description, type, handleChange } = {
    description: '',
    type: LabelType.HEADING,
    tooltip: '',
    handleChange: null,
    ...props,
  }

  return (
    <>
      {!_.isEmpty(text) ? (
        <div
          className={classNames(styles.container, {
            [styles.bodyContainer]: _.isEqual(type, 'body'),
          })}
        >
          <WppTypography
            className={classNames({
              [styles.disabled]: disabled,
              [styles.bodyLabel]: _.isEqual(type, 'body'),
              [styles.label]: !_.isEqual(type, 'body'),
            })}
            id={`label-${id}`}
            type={_.isEqual(type, 'heading') ? 's-strong' : 's-body'}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
              onClick={event => {
                if (_.isEqual(event.target, event.currentTarget) && !disabled && !!handleChange) {
                  handleChange()
                }
              }}
            />
            <div className={styles.tooltip}>
              <FormFieldTooltip helpModal={helpModal} tooltip={tooltip} />
            </div>
          </WppTypography>
        </div>
      ) : (
        <div className={styles.emptyLabel} />
      )}
      {!_.isEmpty(description) && (
        <WppTypography className={styles.description} type="s-body">
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldLabel
