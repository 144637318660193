import { WppTypography, WppGrid, WppStepper } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'

import Action from 'app/components/action'
import Category from 'app/components/category'
import Confirmation from 'app/components/confirmation'
import Form from 'app/components/form'
import Spinner from 'app/components/spinner/Spinner'
import Toast from 'app/components/toast/Toast'
import config, { API_TYPES, TOAST_DURATION } from 'config/constants'
import { AppAction, StepAction, ToastMessageType } from 'config/enums'
import useToast from 'hooks/useToast'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IFieldRequest from 'interfaces/IFieldRequest'
import AxiosService from 'lib/AxiosService'
import ApplicationError from 'pages/appError/ApplicationError'
import styles from 'pages/questionnaire/Questionnaire.module.scss'
import FormHelper from 'utils/form/FormHelper'
import PDFHelper from 'utils/form/PDFHelper'
import SubmitHelper from 'utils/form/SubmitHelper'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

interface IQuestionnaireProps {
  projectId: string

  questionnaireId: string
  itemId: string
}

/**
 * Questionnaire app
 */
const Questionnaire: React.FC<IQuestionnaireProps> = ({
  projectId,
  questionnaireId,
  itemId,
}: IQuestionnaireProps): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const [activeStep, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const TENANT_ID = osContext.tenant.id

  const [appStatus, setAppStatus] = useState<{
    isAppTouched: boolean
    openModal: boolean
  }>({
    isAppTouched: false,
    openModal: false,
  })
  const [app, setApp] = useState<IApp | null>(null)
  const { showToast } = useToast()
  const [error, setError] = useState<boolean>(false)

  /**
   * Handle Stepper change
   * @param {number} stepNumber
   */
  const handleStep = async (stepNumber: number, stepAction: StepAction) => {
    try {
      if (!app) {
        return
      }

      if (_.gt(stepNumber, app.categories.length)) {
        return
      }

      if (_.isEqual(stepNumber, 0) && _.isEqual(stepAction, StepAction.PREVIOUS)) {
        return
      }

      if (_.isEqual(stepAction, StepAction.NEXT) && app.isAppEditor) {
        setLoading(true)
        await onSaveProgress(app.categories[activeStep - 1])
        setAppStatus((prevState: any) => ({
          ...prevState,
          isAppTouched: false,
        }))

        setLoading(false)
      }

      setStep(stepNumber)
    } catch (error) {
      setLoading(false)
      showAlert(
        'Oops! Something went wrong',
        'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        ToastMessageType.ERROR,
      )
    }
  }

  /**
   * Save form data
   * @param {ICategory} category
   */
  const onSaveProgress = async (category: ICategory): Promise<void> => {
    if (!app) {
      return
    }
    const submitHelper = new SubmitHelper()

    const formHelper = new FormHelper()
    const UpdatedCategory = await formHelper.preSaveCheck(
      category,
      osApi.getAccessToken(),
      TENANT_ID,
      osContext.tenant.homeUrl,
      projectId,
    )

    setApp((prevState: any) => ({
      ...prevState,
      categories: prevState.categories.map((categoryParam: ICategory) => {
        if (_.isEqual(categoryParam.id, UpdatedCategory.id)) {
          return UpdatedCategory
        }
        return categoryParam
      }),
    }))

    const FormData: IFieldRequest[] = submitHelper.onSaveProgress(UpdatedCategory, TENANT_ID, projectId, itemId)

    await submitHelper.saveToDb(osApi.getAccessToken(), TENANT_ID, FormData)
  }

  const showAlert = (header: string, message: string, type: ToastMessageType) => {
    showToast({
      header,
      message,
      type,
      duration: TOAST_DURATION,
    })
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   */
  const handleAppAction = async (appAction: string): Promise<void> => {
    if (!app) {
      return
    }
    setLoading(true)
    try {
      const submitHelper = new SubmitHelper()
      switch (appAction) {
        case AppAction.SAVE_AND_EXIT: {
          const RequestData = submitHelper.onSaveAndExit(
            app.categories,
            osContext.userDetails.id,
            TENANT_ID,
            projectId,
            itemId,
          )
          await submitHelper.saveToDb(osApi.getAccessToken(), TENANT_ID, RequestData)

          setAppStatus((prevState: any) => ({
            ...prevState,
            isAppTouched: false,
          }))

          const timer = setTimeout(() => {
            window.history.pushState(null, '', `${osContext.tenant.homeUrl}orchestration/project/${projectId}/workflow`)

            clearTimeout(timer)
          }, 1000)

          showAlert('success', 'The data have been submitted', ToastMessageType.SUCCESS)
          break
        }
        case AppAction.SAVE_PROGRESS: {
          await onSaveProgress(app.categories[activeStep - 1])
          setAppStatus((prevState: any) => ({
            ...prevState,
            isAppTouched: false,
          }))
          showAlert('success', 'The data have been submitted', ToastMessageType.SUCCESS)
          break
        }

        case AppAction.CANCEL: {
          if (appStatus.isAppTouched) {
            setAppStatus((prevState: any) => ({
              ...prevState,
              openModal: true,
            }))
          } else {
            window.history.pushState(null, '', `${osContext.tenant.homeUrl}orchestration/project/${projectId}/workflow`)
          }
          break
        }
        default:
          break
      }
      setLoading(false)
    } catch {
      showAlert(
        'Oops! Something went wrong',
        'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        ToastMessageType.ERROR,
      )
      setLoading(false)
    }
  }

  const initLoad = useCallback(async () => {
    try {
      if (!projectId) {
        return
      }
      setStep(1)
      setApp(null)

      const formHelper = new FormHelper()
      const axiosService = new AxiosService(osApi.getAccessToken())
      const Response: any = await axiosService.get(
        `${
          config[API_TYPES.QUESTIONNAIRE_API]
        }/questionnaire?questionnaire_id=${questionnaireId}&project_id=${projectId}&tenant_id=${TENANT_ID}&item_id=${itemId}`,
        TENANT_ID,
      )
      const UpdatedApp: IApp = await formHelper.getApp(
        Response.data,
        osContext.permissions,
        osApi.getAccessToken(),
        TENANT_ID,
        projectId,
      )

      setApp(UpdatedApp)
    } catch {
      setError(true)
    }
  }, [osApi, osContext.permissions, TENANT_ID, projectId, questionnaireId, itemId])

  /**
   * Handle form field change
   * @param {IFieldChangeEvent} event
   */
  const handleChange = (event: IFieldChangeEvent): void => {
    if (!app) return

    const formFieldHelper = new FormFieldHelper()

    if (!formFieldHelper.isValidChange(event)) return

    const formHelper = new FormHelper()
    const UpdatedApp = formHelper.updateApp(app, event)
    setAppStatus((prevState: any) => ({
      ...prevState,
      isAppTouched: true,
    }))
    setApp(UpdatedApp)
  }

  const downloadForm = () => {
    if (!app) return
    const pdfHelper = new PDFHelper()
    pdfHelper.getPDFData(app, osContext)
  }

  useEffect(() => {
    initLoad()
  }, [initLoad])

  console.log(osContext)

  if (error) {
    return <ApplicationError />
  }

  return (
    <>
      {!!app ? (
        <>
          <WppGrid container className={styles.layout} rowSpacing={2}>
            <WppGrid item all={24} className={styles.layoutHeader}>
              <WppTypography tag="h1" type="2xl-heading">
                {app.appName}
              </WppTypography>
              {!_.isEmpty(app.categories) && (
                <div className={styles.actions}>
                  <Action
                    app={app}
                    activeStep={activeStep}
                    handleAppAction={handleAppAction}
                    handleStep={handleStep}
                    isValidSubmit={app.isValid}
                    isAppEditor={app.isAppEditor}
                    downloadForm={downloadForm}
                  />
                </div>
              )}
            </WppGrid>
            {!_.isEmpty(app.categories) && !_.isEqual(app.categories.length, 1) && (
              <WppGrid className={styles.layoutCategories} item all={4}>
                <WppStepper completedSteps={activeStep - 1} activeStep={activeStep}>
                  {app.categories.map((category: ICategory) => (
                    <Category key={category.id} category={category} />
                  ))}
                </WppStepper>
              </WppGrid>
            )}

            {!_.isEmpty(app.categories) && (
              <WppGrid item all={!_.isEqual(app.categories.length, 1) ? 20 : 24}>
                <div>
                  <Form
                    completionRate={app.completionRate}
                    category={app.categories[activeStep - 1]}
                    handleChange={handleChange}
                    app={app}
                  />
                </div>
              </WppGrid>
            )}
          </WppGrid>
          <Confirmation
            title={`Leave ${app.appName} form`}
            body={`Are you sure you want to leave your ${app.appName} form? Your progress will be lost.`}
            btnPrimaryText="Leave"
            btnSecondaryText="Cancel"
            handlePrimaryAction={() =>
              window.history.pushState(
                null,
                '',
                `${osContext.tenant.homeUrl}orchestration/project/${projectId}/workflow`,
              )
            }
            handleSecondaryAction={() =>
              setAppStatus((prevState: any) => ({
                ...prevState,
                openModal: false,
              }))
            }
            isOpen={appStatus.openModal}
          />
        </>
      ) : (
        <Spinner />
      )}
      {loading && <Spinner />}
      <Toast />
    </>
  )
}

export default Questionnaire
