import { WppButton, WppIconButton, WppIconDownload } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styles from 'app/components/action/Action.module.scss'
import { AppAction, StepAction } from 'config/enums'
import IApp from 'interfaces/IApp'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

const formFieldHelper = new FormFieldHelper()

interface IActionProps {
  /** IApp interface */
  app: IApp
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (appAction: string) => void
  downloadForm: () => void
  /** Handle Next/Previous step event*/
  handleStep: (stepNumber: number, stepAction: StepAction) => void
  /** Active step*/
  activeStep: number
  /** Is valid for submit*/
  isValidSubmit: boolean
  /** if user has editor access*/
  isAppEditor: boolean
}

/**
 * App action
 * @param {object} props
 * @param {IApp[]} props.app
 * @param {Function} props.handleAppAction
 * @param {Function} props.handleStep
 * @param {Function} props.downloadForm
 * @param {number} props.activeStep
 * @param {boolean} props.isValidSubmit
 * @param {boolean} props.isAppEditor
 */
const Action: React.FC<IActionProps> = ({
  app,
  handleAppAction,
  handleStep,
  downloadForm,
  activeStep,
  isValidSubmit,
  isAppEditor,
}: IActionProps): React.ReactElement => {
  const fieldsData = formFieldHelper.getFormFieldsObject(app.categories)
  let btnNextOrSubmit = (
    <WppButton
      id="btn-next"
      onClick={() => handleStep(activeStep + 1, StepAction.NEXT)}
      className={styles.btnNextSubmit}
      disabled={!app.categories[activeStep - 1].isValid || _.isEqual(app.categories.length, activeStep)}
    >
      Next
    </WppButton>
  )

  // If all steps are filled
  if (_.isEqual(app.categories.length, activeStep) && isAppEditor) {
    btnNextOrSubmit = (
      <WppButton
        disabled={!isValidSubmit}
        id="btn-submit"
        onClick={() => handleAppAction(AppAction.SAVE_AND_EXIT)}
        className={styles.btnNextSubmit}
      >
        Save and Exit
      </WppButton>
    )
  }

  return (
    <>
      {_.isEqual(app.appName, 'Idea Brief') && (
        <CopyToClipboard
          text={`Brand: Coca-Cola Campaign for FIFA Women's World Cup 2027\nHosting countries: US and Mexico\nInsights: ${fieldsData['the insights']}\nAudiences: Players, National Fans, Superstar Fans, Coerced Companions, Extended Family, Nuclear Family, First Timers, City Citizens, Party Goers, Young Culturalist Couples, Weekend Socialites, Hall Passers, Sports Fans, Patriots\nSuggest ideas for each of the activation types: digital, live, product and retail activations has context menu`}
        >
          <WppButton id="btn-copy-campaign-details" className={styles.btnNextSubmit}>
            Copy campaign details
          </WppButton>
        </CopyToClipboard>
      )}
      <WppIconButton size="s" onClick={downloadForm}>
        <WppIconDownload />
      </WppIconButton>

      {isAppEditor && !_.isEqual(app.categories.length, 1) && (
        <WppButton
          id="btn-save-progress"
          onClick={() => handleAppAction(AppAction.SAVE_PROGRESS)}
          disabled={!app.categories[activeStep - 1].isValid}
          className={classNames(styles.btnSaveProgress, {
            [styles.disabled]: !app.categories[activeStep - 1].isValid,
          })}
        >
          Save Progress
        </WppButton>
      )}

      <WppButton id="btn-cancel" onClick={() => handleAppAction(AppAction.CANCEL)} variant="secondary">
        Cancel
      </WppButton>
      {!_.isEqual(app.categories.length, 1) && (
        <WppButton
          id="btn-back"
          onClick={() => handleStep(activeStep - 1, StepAction.PREVIOUS)}
          disabled={_.isEqual(activeStep, 1)}
        >
          Previous
        </WppButton>
      )}
      {btnNextOrSubmit}
    </>
  )
}

export default Action
