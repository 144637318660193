// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p3mMQ{width:100%}.p3mMQ::part(step-bg),.p3mMQ::part(step-wrapper){width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/category/Category.module.scss"],"names":[],"mappings":"AAAA,OAKE,UAAA,CAJA,iDAEE,UAAA","sourcesContent":[".customStyleStep {\n  &::part(step-bg),\n  &::part(step-wrapper) {\n    width: 100%;\n  }\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customStyleStep": `p3mMQ`
};
export default ___CSS_LOADER_EXPORT___;
