import axios, { AxiosInstance, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import axiosRetry from 'axios-retry'

class AxiosService {
  private instance: AxiosInstance
  private accessToken: string
  constructor(accessToken: string) {
    this.instance = axios.create()

    this.accessToken = accessToken
    axiosRetry(this.instance, {
      retryDelay: (retryCount: number) => retryCount * 2000,
      retries: 5,
    })
  }

  private getRequestHeader = (tenantId: string, headers?: any): RawAxiosRequestHeaders => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.accessToken}`,
    'X-Tenant-Id': tenantId,
    ...headers,
  })

  public get<T>(url: string, tenantId: string): Promise<T> {
    return this.instance.get(url, {
      headers: this.getRequestHeader(tenantId),
    })
  }

  public post<T>(url: string, data: any, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return this.instance.post(url, data, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }

  public put<T>(url: string, data: any, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return this.instance.put(url, data, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }
}

export default AxiosService
