import _ from 'lodash'

import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IResponseField from 'interfaces/field/response/IResponseField'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import ICompletionRate from 'interfaces/ICompletionRate'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IForm from 'interfaces/IForm'
import IOption from 'interfaces/IOption'
import FileHelper from 'utils/file/FileHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'
import SharedHelper from 'utils/SharedHelper'

export default class FormFieldHelper {
  private formFieldValidation: FormFieldValidation
  private sharedHelper: SharedHelper
  constructor() {
    this.formFieldValidation = new FormFieldValidation()
    this.sharedHelper = new SharedHelper()
  }

  /**
   * Validate field value change
   * @param {IFieldChangeEvent} event
   * @returns {boolean}
   */
  isValidChange(event: IFieldChangeEvent): boolean {
    const { field, value } = event
    let isValidChange = false
    switch (field.type) {
      case FieldType.COUNTER:
      case FieldType.RADIO:
      case FieldType.TEXT_INPUT:
      case FieldType.DATE_PICKER:
      case FieldType.TEXT_AREA: {
        isValidChange = !_.isEqual(value, field.value)
        break
      }
      case FieldType.FILE_UPLOAD: {
        isValidChange = true
        break
      }
      case FieldType.CURRENCY: {
        if (!value) {
          isValidChange = true
        } else {
          isValidChange =
            !_.isEqual(value.currencyType, field.value.currencyType) ||
            !_.isEqual(value.currencyAmount, field.value.currencyAmount)
        }
        break
      }
      case FieldType.SELECT: {
        isValidChange = field.fieldConfig.multi
          ? !_.isEmpty(this.sharedHelper.isDiffArray(value, field.value))
          : !_.isEqual(value, field.value)
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        isValidChange = !_.isEmpty(this.sharedHelper.isDiffArray(value, field.value))
        break
      }
    }
    return isValidChange
  }

  /**
   * Get default value
   * @param {IField} field
   * @returns {any}
   */
  getDefaultValue(field: IField): any {
    let value = null
    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA: {
        value = ''
        break
      }
      case FieldType.SELECT: {
        value = field.fieldConfig.multi ? [] : null
        break
      }
      case FieldType.DATE_PICKER: {
        value = null
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.FILE_UPLOAD:
      case FieldType.AUTOCOMPLETE: {
        value = []
        break
      }
    }
    return value
  }

  /**
   * Get valid value
   * @param {IField} field
   * @returns {any}
   */
  getValidValue(field: IResponseField, inputValue: any): any {
    let value = inputValue
    switch (field.fieldType) {
      case FieldType.TEXT_INPUT:
      case FieldType.DATE_PICKER:
      case FieldType.TEXT_AREA: {
        value = _.isString(inputValue) ? inputValue : ''
        break
      }
      case FieldType.RADIO: {
        value = !inputValue ? null : inputValue
        break
      }
      case FieldType.CURRENCY: {
        value = !inputValue
          ? {
              currencyType: null,
              currencyAmount: '',
            }
          : inputValue
        break
      }
      case FieldType.SELECT: {
        if (field.config && field.config.multi) {
          value = _.isArray(inputValue) ? inputValue : []
        } else {
          value = _.isArray(inputValue) ? null : inputValue
        }
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.FILE_UPLOAD:
      case FieldType.AUTOCOMPLETE: {
        if (field.config) {
          value = field.config.multi ? (_.isArray(inputValue) ? inputValue : []) : inputValue
          value = _.isArray(value) ? value : []
        }

        break
      }
    }
    return value
  }

  /**
   * Update an field
   * @param {IField} field
   * @param {InputFieldChangeEvent} event
   * @returns {IField}
   */
  updateField(field: IField, event: IFieldChangeEvent): IField {
    if (_.isEqual(field.id, event.field.id)) {
      const IsValid = this.formFieldValidation.validate(field, event.value)

      const Field: IField = {
        ...field,
        value: event.value,
        touched: true,
        isValid: IsValid && !event.hasError,
      }

      if (IsValid) {
        const { messageType, errorMessage, ...rest } = Field
        return rest
      }

      return {
        ...Field,
        messageType: 'error',
      }
    }

    return {
      ...field,
      children: field.children.map((childField: IField) => {
        return this.updateField(childField, event)
      }),
    }
  }

  /**
   * Pre-save check category for file upload
   * @param {IField} field
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<IField>}
   */
  async preSaveFieldCheck(
    field: IField,
    accessToken: string,
    tenantId: string,
    tenantUrl: string,
    projectId: string,
  ): Promise<IField> {
    const Field: IField = {
      ...field,
    }
    const Value = Field.value
    if (_.isEqual(Field.type, FieldType.FILE_UPLOAD)) {
      const fileHelper = new FileHelper(accessToken)
      const UpdatedFiles = []

      for (let value of Value) {
        if (!value.id && !value.file.formatError) {
          const UpdatedFile = await fileHelper.uploadFile(value.file, tenantId, tenantUrl, projectId)
          UpdatedFiles.push(UpdatedFile)
        } else {
          UpdatedFiles.push(value)
        }
      }
      Field.value = UpdatedFiles
    }
    return Field
  }

  /**
   * Get field children
   * @param {IField} field
   * @param {any} value
   * @returns {IField[]}
   */

  getChildren(field: IField, value: any): IField[] {
    if (_.isEqual(field.type, FieldType.CHECKBOX)) {
      const Values = value.map((option: IOption) => _.toString(option.id))
      return field.children.filter((childField: IField) => {
        return this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, Values)
      })
    }

    return field.children.filter((childField: IField) =>
      this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(value)]),
    )
  }

  /**
   * Get field by id
   * @param {ICategory[]} field
   * @param {string} fieldId
   * @returns {IField}
   */
  findFieldById(categories: ICategory[], fieldId: string): IField | null {
    let field = null
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          if (_.isEqual(fieldParam.id, fieldId)) {
            field = fieldParam
          }
        })
      })
    })
    return field
  }

  /**
   * Get fields in object
   * @param {ICategory[]} field
   * @param {boolean} isEmpty
   * @returns {IField}
   */
  getFormFieldsObject(categories: ICategory[], isEmpty?: boolean): { [key: string]: string } {
    let field: { [key: string]: string } = {}
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          field[_.toLower(fieldParam.fieldConfig.text)] = isEmpty ? '' : fieldParam.value
        })
      })
    })
    return field
  }

  /**
   * Get field by label
   * @param {ICategory[]} field
   * @returns {IField}
   */
  findFieldByLabel(categories: ICategory[], fieldLabel: string): string {
    let fieldValue = ''
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          if (_.isEqual(fieldParam.fieldConfig.text, fieldLabel)) {
            fieldValue = fieldParam.value
          }
        })
      })
    })
    return fieldValue
  }

  /**
   * Get field by form field id
   * @param {ICategory[]} field
   * @param {string} fieldId
   * @returns {IField[]}
   */
  findFieldByFormFieldId(categories: ICategory[], formFieldId: string): IField | null {
    let field = null
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          if (_.isEqual(fieldParam.formFieldId, formFieldId)) {
            field = fieldParam
          }
        })
      })
    })
    return field
  }

  /**
   * If the field is hidden
   * @param {IField} parentField
   * @param {IField} childField
   * @returns {boolean}
   */
  isFieldHidden(parentField: IField, childField: IField): boolean {
    if (_.isArray(parentField.value)) {
      const IsValidValue = parentField.value.findIndex((option: IOption) =>
        this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(option.id)]),
      )
      return _.isEqual(IsValidValue, -1)
    }
    return !this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(parentField.value)])
  }

  /**
   * Count total no of fields
   * @param {IApp} app
   * @returns {ICompletionRate}
   */
  getCompletionRate(app: IApp): ICompletionRate {
    let completed = 0
    let total = 0

    app.categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((field: IField) => {
          if (!field.isHidden) {
            completed = this.formFieldValidation.isEmptyField(field) ? completed : completed + 1
            total = total + 1
          }
        })
      })
    })
    return {
      completed,
      total,
      percentage: _.isEqual(completed, 0) ? 1 : Math.round((completed / total) * 100),
    }
  }
}
