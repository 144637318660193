import _ from 'lodash'

import config, { API_TYPES } from 'config/constants'
import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFile from 'interfaces/file/IFile'
import ICategory from 'interfaces/ICategory'
import IFieldRequest from 'interfaces/IFieldRequest'
import IOption from 'interfaces/IOption'
import IProjectQuestionnaireRequest from 'interfaces/IProjectQuestionnaireRequest'
import AxiosService from 'lib/AxiosService'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

export default class SubmitHelper {
  private formFieldHelper: FormFieldHelper

  /**
   *  Creates an instance of FormHelper
   */
  constructor() {
    this.formFieldHelper = new FormFieldHelper()
  }

  /**
   * Return display value for submit
   * @param {IField} field
   * @returns {boolean}
   */
  isValueChange(field: IField, fieldValue: any): boolean {
    let isValueChange: boolean = false

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.RADIO:
      case FieldType.DATE_PICKER:
      case FieldType.SELECT: {
        isValueChange = !_.isEqual(fieldValue, field.initialValue)
        break
      }
      case FieldType.FILE_UPLOAD: {
        isValueChange = !_.isEqual(
          _.sortBy(fieldValue.map((file: IFile) => file.id)),
          _.sortBy(field.initialValue.map((file: IFile) => file.id)),
        )
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        isValueChange = !_.isEqual(
          _.sortBy(fieldValue.map((option: IOption) => option.id)),
          _.sortBy(field.initialValue.map((option: IOption) => option.id)),
        )
        break
      }
    }
    return isValueChange
  }

  /**
   * Build Save Request Data
   * @param {IField} field
   * @param {string} userId
   * @param {string} tenantId
   * @param {string} fieldValue
   * @param {string} displayValue
   * @returns {IFieldRequest}
   */
  buildSaveRequestData(
    field: IField,
    tenantId: string,
    projectId: string,
    fieldValue: any,
    oldValue: any,
    displayValue: string,
    itemId: string,
  ): IFieldRequest {
    return {
      formFieldId: field.formFieldId,
      value: {
        value: fieldValue,
      },
      // isValueChange: this.isValueChange(field, fieldValue),
      displayValue: displayValue,
      // oldValue,
      tenantId: tenantId,
      projectId: projectId,
      itemId,
    }
  }

  /**
   * Return select value for submit
   * @param {IField} field
   * @returns {any}
   */
  getSelectValue(field: IField) {
    if (!field.value) {
      return ''
    }
    let value = _.isArray(field.value) ? field.value : [field.value]
    value = value.map((val: any) => _.toString(val))
    return _.map(
      field.fieldConfig.options.filter((option: IOption) => !_.isEqual(value.indexOf(_.toString(option.id)), -1)),
      (option: IOption) => option.label,
    ).join(', ')
  }

  /**
   * Return display value for submit
   * @param {IField} field
   * @returns {any}
   */
  getSubmitDisplayValues(field: IField): any {
    let value = ''

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.DATE_PICKER: {
        value = field.value
        break
      }
      case FieldType.FILE_UPLOAD: {
        value = field.value.map(({ file }: any) => file.url || '').join(', ')
        break
      }
      case FieldType.RADIO:
      case FieldType.SELECT: {
        value = this.getSelectValue(field)
        break
      }
      case FieldType.CURRENCY: {
        value = `${field.value.currencyAmount} ${field.value.currencyType}`
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        value = field.value
          .map((option: IOption) => `${option.label}${!option.subLabel ? '' : ` - ${option.subLabel}`}`)
          .join(', ')
        break
      }
    }
    return value
  }

  /**
   * Save Form Progress
   * @param {ICategory} category
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {IResponse[]}
   */
  onSaveProgress(category: ICategory, tenantId: string, projectId: string, itemId: string): IFieldRequest[] {
    const Fields: IFieldRequest[] = []
    for (let form of category.forms) {
      form.fields.forEach((field: IField) => {
        const Value = field.isHidden ? this.formFieldHelper.getDefaultValue(field) : field.value
        const DisplayValue = field.isHidden ? '' : this.getSubmitDisplayValues(field)
        Fields.push(
          this.buildSaveRequestData(field, tenantId, projectId, Value, field.initialValue, DisplayValue, itemId),
        )
        field.children.forEach((childField: IField) => {
          const ChildValue = childField.isHidden ? this.formFieldHelper.getDefaultValue(childField) : childField.value
          const ChildDisplayValue = childField.isHidden ? '' : this.getSubmitDisplayValues(childField)
          Fields.push(
            this.buildSaveRequestData(
              childField,
              tenantId,
              projectId,
              ChildValue,
              field.initialValue,
              ChildDisplayValue,
              itemId,
            ),
          )
        })
      })
    }

    return Fields
  }

  /**
   * Save & Exit
   * @param {ICategory[]} category
   * @param {string} userId
   * @param {string} tenantId
   * @returns {IResponse[]}
   */
  onSaveAndExit(
    categories: ICategory[],
    userId: string,
    tenantId: string,
    projectId: string,
    itemId: string,
  ): IFieldRequest[] {
    let fields: IFieldRequest[] = []

    categories.forEach((category: ICategory) => {
      fields = _.concat(fields, this.onSaveProgress(category, tenantId, projectId, itemId))
    })
    return fields
  }

  /**
   * Save data
   * @param {string} accessToken
   * @param {IFieldRequest[]} formData
   * @returns {Promise<void>}
   */
  saveToDb = async (accessToken: string, tenantId: string, formData: IFieldRequest[]): Promise<void> => {
    const axiosService = new AxiosService(accessToken)
    await axiosService.post(`${config[API_TYPES.QUESTIONNAIRE_API]}/save_data`, formData, tenantId)
  }

  /**
   * Save & Exit
   * @param {string} accessToken
   * @param {IProjectQuestionnaireRequest} formData
   * @returns {Promise<void>}
   */
  onSaveProjectQuestionnaire = async (
    accessToken: string,
    tenantId: string,
    formData: IProjectQuestionnaireRequest,
  ): Promise<void> => {
    const axiosService = new AxiosService(accessToken)
    await axiosService.post(`${config[API_TYPES.QUESTIONNAIRE_API]}/project_questionnaire`, formData, tenantId)
  }
}
