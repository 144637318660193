import _ from 'lodash'

import { Currency, FieldType } from 'config/enums'
import IResponseField from 'interfaces/field/response/IResponseField'
import IOption from 'interfaces/IOption'
import AxiosService from 'lib/AxiosService'

export default class SharedHelper {
  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidTextValue(value: any): boolean {
    return !_.isEmpty((value || '').toString().trim())
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidValue(value: any): boolean {
    if (_.isNumber(value)) {
      return true
    }
    return this.isValidTextValue(value)
  }

  /**
   * Difference of two array
   * @param {array} x
   * @param {array} y
   * @returns {boolean}
   */
  isDiffArray = (x: any, y: any): any => {
    return _.xorWith(x, y, _.isEqual)
  }

  /**
   * Check and return valid array
   * @param {any[]} array
   * @returns {any[]}
   */
  getValidArray = (array: any): any[] => {
    return _.isArray(array) ? array : []
  }

  /**
   * Build Option Array
   * @param {any[]} array
   * @param {idKey} Option ID key
   * @param {labelKey} Option label key
   * @returns {IOption[]}
   */
  getOptions = (array: any[], idKey: string, labelKey: string): IOption[] => {
    return array.map((data: any) => ({
      id: data[idKey],
      label: data[labelKey],
    }))
  }

  /**
   * Get Currency List
   * @returns {IOption[]}
   */
  getCurrencyList = (): IOption[] => {
    const CurrencyList: IOption[] = []
    for (let key in Currency) {
      const label: string = `${key} - ${Currency[key as keyof typeof Currency]}`
      CurrencyList.push({
        id: label,
        label,
      })
    }
    return CurrencyList
  }

  getLabel = (input: string[], inputResponse: any): string => {
    let label = ''

    input.forEach((data: string) => {
      label = _.isEmpty(label) ? inputResponse[data] : `${label} ${inputResponse[data]}`
    })

    return label
  }

  getSelectOptions = async (field: IResponseField, accessToken: string, tenantId: string): Promise<IOption[]> => {
    const { options, apiUrl } = field
    if (!_.isEmpty(options)) return options

    if (_.isEqual(field.fieldType, FieldType.CURRENCY)) {
      return this.getCurrencyList()
    }
    if (_.isEqual(field.fieldType, FieldType.AUTOCOMPLETE)) {
      return options
    }

    if (!accessToken || !apiUrl) return options || []

    const axiosService = new AxiosService(accessToken)

    const { keys }: any = field.config?.autocomplete
    const { id, label } = keys

    const Result: any = await axiosService.get(apiUrl, tenantId)
    const Response = _.isArray(Result.data.data) ? Result.data.data : []
    return Response.map((data: any) => {
      return {
        id: data[id],
        label: this.getLabel(label, data),
      }
    })
  }

  findAnyArrayValueInTargetArray = (inputArray: string[], targetArray: string[]): boolean => {
    for (let value of targetArray) {
      for (let inputValue of inputArray) {
        if (_.isEqual(_.toString(value), _.toString(inputValue))) {
          return true
        }
      }
    }
    return false
  }
}
