import { WppGrid, WppProgressIndicator, WppTypography } from '@platform-ui-kit/components-library-react'
import * as _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import FormBuilder from 'app/components/form/formBuilder/FormBuilder'
import IdeaBriefSummaryTemplate from 'app/templates/IdeaBrief'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import ICompletionRate from 'interfaces/ICompletionRate'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'

interface IFormProps {
  /** Questionnaire app */
  app: IApp
  /** ICategory interface */
  category: ICategory
  /** ICompletionRate interface */
  completionRate: ICompletionRate
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Display form
 * @param {object} props
 * @param {ICategory} props.category
 */
const Form: React.FC<IFormProps> = ({
  app,
  category,
  handleChange,
  completionRate,
}: IFormProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <WppGrid fullWidth container>
        <WppGrid item xl={24} xxl={24} sm={24} md={24} all={24}>
          <WppTypography className={styles.completionRate} tag="p" type="s-body">
            You completed <span className={styles.completionNumber}>{completionRate.completed}</span> of{' '}
            <span className={styles.completionNumber}>{completionRate.total}</span> fields.
          </WppTypography>
          <WppProgressIndicator variant="bar" value={completionRate.percentage} />
        </WppGrid>
        <WppGrid item xl={12} xxl={12} sm={24} md={16} all={24}>
          <WppTypography className={styles.categoryName} tag="h1" type="xl-heading">
            {category.name}
          </WppTypography>
          {!_.isEmpty(category.description) && (
            <p className={styles.description}>
              <span
                dangerouslySetInnerHTML={{
                  __html: category.description,
                }}
              />
            </p>
          )}

          <FormBuilder forms={category.forms} handleChange={handleChange} />
        </WppGrid>
        <WppGrid item xl={24} xxl={24} sm={24} md={24} all={24}>
          {_.isEqual(app.appName, 'Idea Brief') && _.isEqual(category.name, 'The Summary') && (
            <IdeaBriefSummaryTemplate app={app} />
          )}
          {_.isEqual(app.appName, 'Idea Brief') && _.isEqual(category.name, 'Template') && (
            <IdeaBriefSummaryTemplate app={app} isEmpty />
          )}
        </WppGrid>
      </WppGrid>
    </div>
  )
}

export default Form
