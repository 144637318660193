import { convert } from 'html-to-text'
import { jsPDF } from 'jspdf'
import _ from 'lodash'

import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFile from 'interfaces/file/IFile'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import IForm from 'interfaces/IForm'
import IOption from 'interfaces/IOption'

export default class PDFHelper {
  /**
   * Return select value
   * @param {IField} field
   * @returns {any}
   */
  getSelectValue(field: IField): string {
    if (!field.value) {
      return ''
    }
    let value = _.isArray(field.value) ? field.value : [field.value]
    value = value.map((val: any) => _.toString(val))
    return `<ul>${_.map(
      field.fieldConfig.options.filter((option: IOption) => !_.isEqual(value.indexOf(_.toString(option.id)), -1)),
      (option: IOption) => `<li>${option.label}</li>`,
    ).join('')}</ul>`
  }

  /**
   * Return radio value
   * @param {IField} field
   * @returns {any}
   */
  getRadioValue(field: IField): string {
    if (!field.value) {
      return ''
    }
    const Value: IOption | undefined = field.fieldConfig.options.find((option: IOption) =>
      _.isEqual(option.id, field.value),
    )

    return !Value ? '' : Value.label
  }

  /**
   * Return Field value
   * @param {IField} field
   * @returns {any}
   */
  getFieldValue(field: IField): any {
    let value = ''

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.DATE_PICKER: {
        value = field.value
        break
      }
      case FieldType.FILE_UPLOAD: {
        value = field.value || []
        break
      }
      case FieldType.RADIO: {
        value = this.getRadioValue(field)
        break
      }
      case FieldType.SELECT: {
        value = this.getSelectValue(field)
        break
      }
      case FieldType.CURRENCY: {
        value = `${field.value.currencyAmount} ${field.value.currencyType}`
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        value = `<ul>${field.value
          .map((option: IOption) => `<li>${option.label}${!option.subLabel ? '' : ` - ${option.subLabel}`}</li>`)
          .join('')}</ul>`
        break
      }
    }
    return value
  }

  /**
   * Return PDF Text
   * @param {jsPDF} doc
   * @param {string} content
   * @param {number} fontSize
   * @param {number} pageXPosition
   * @param {number} pageYPosition
   * @param {string | number} fontWeight
   * @param {boolean} isHTMLContent
   * @returns {number}
   */
  getText(
    doc: jsPDF,
    content: string,
    fontSize: number,
    pageXPosition: number = 20,
    pageYPosition: number,
    fontWeight: string | number = 'normal',
    isHTMLContent: boolean = true,
  ): number {
    doc.setFontSize(fontSize)
    doc.setFont('times', '', fontWeight)
    let text = doc.splitTextToSize(isHTMLContent ? convert(content) : content, 180)

    let pageHeight = doc.internal.pageSize.getHeight()

    for (let i = 0; i < text.length; i++) {
      if (_.gt(pageYPosition + 10, pageHeight)) {
        pageYPosition = 20
        doc.addPage()
      }
      doc.text(text[i], pageXPosition, pageYPosition)
      pageYPosition = pageYPosition + 5
    }
    pageYPosition = pageYPosition + 5

    return pageYPosition
  }

  /**
   * Return File Link
   * @param {jsPDF} doc
   * @param {IFile[]} files
   * @param {number} fontSize
   * @param {number} pageXPosition
   * @param {number} pageYPosition
   * @param {string | number} fontWeight
   * @returns {number}
   */
  getFileLink(
    doc: jsPDF,
    files: IFile[],
    fontSize: number,
    pageXPosition: number = 20,
    pageYPosition: number,
    fontWeight: string | number = 'normal',
  ): number {
    doc.setFontSize(fontSize)
    doc.setFont('times', '', fontWeight)

    let pageHeight = doc.internal.pageSize.getHeight()
    for (let i = 0; i < files.length; i++) {
      if (pageYPosition + 10 > pageHeight) {
        pageYPosition = 20
        doc.addPage()
      }
      doc.textWithLink(files[i].file.name, pageXPosition, pageYPosition, {
        url: files[i].file.url,
      })
      pageYPosition = pageYPosition + 5
    }
    pageYPosition = pageYPosition + 5

    return pageYPosition
  }

  /**
   * Return Field
   * @param {jsPDF} doc
   * @param {IField} field
   * @param {number} pageXPosition
   * @param {number} pageYPosition
   * @param {boolean} isChildField
   * @returns {number}
   */
  getField(doc: jsPDF, field: IField, pageYPosition: number, isChildField: boolean = false): number {
    let yPosition = pageYPosition
    const PageXPosition = isChildField ? 25 : 20
    const IsHTMLContent =
      _.isEqual(field.type, FieldType.CHECKBOX) ||
      _.isEqual(field.type, FieldType.AUTOCOMPLETE) ||
      _.isEqual(field.type, FieldType.SELECT)

    if (!field.isHidden) {
      if (!_.isEmpty(field.fieldConfig.text)) {
        yPosition = this.getText(doc, field.fieldConfig.text || '', 12, PageXPosition, yPosition, 'bold')
      }

      if (_.isEqual(field.type, FieldType.FILE_UPLOAD)) {
        yPosition = this.getFileLink(doc, this.getFieldValue(field), 12, PageXPosition, yPosition)
      } else {
        yPosition = this.getText(doc, this.getFieldValue(field), 12, PageXPosition, yPosition, 'normal', IsHTMLContent)
      }
      field.children.forEach((childField: IField) => {
        yPosition = this.getField(doc, childField, yPosition, true)
      })
    }

    return yPosition
  }

  /**
   * Return PDF Data
   * @param {IApp} app
   * @param {any} osContext
   * @returns {void}
   */
  getPDFData(app: IApp, osContext: any): void {
    let pdfDOC: jsPDF = new jsPDF()

    let pageYPosition = 20
    app.categories.forEach((category: ICategory, index: number) => {
      pageYPosition = this.getText(pdfDOC, category.name, 20, 20, pageYPosition, 'bold')
      pageYPosition = this.getText(pdfDOC, category.description || '', 14, 20, pageYPosition)

      category.forms.forEach((form: IForm) => {
        pageYPosition = this.getText(pdfDOC, form.name || '', 14, 20, pageYPosition, 'bold')
        form.fields.forEach((field: IField) => {
          if (!field.isHidden) {
            pageYPosition = this.getField(pdfDOC, field, pageYPosition)
          }
        })
      })

      if (_.lt(index + 1, app.categories.length)) {
        pdfDOC.addPage()
      }

      pageYPosition = 20
    })

    pdfDOC.save(`${osContext.project.name}-${app.appName}.pdf`)
  }
}
