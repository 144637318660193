// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aN75I{display:flex;padding-top:.5rem}.fBTGv{flex-direction:column}.FDPfU{flex-direction:row}.OFwu1{margin-top:.5rem}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldCheckbox/FormFieldCheckbox.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,iBAAA,CAEF,OACE,qBAAA,CAGF,OACE,kBAAA,CAGF,OACE,gBAAA","sourcesContent":[".container {\n  display: flex;\n  padding-top: 0.5rem;\n}\n.displayVertical {\n  flex-direction: column;\n}\n\n.displayHorizontal {\n  flex-direction: row;\n}\n\n.checkbox {\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aN75I`,
	"displayVertical": `fBTGv`,
	"displayHorizontal": `FDPfU`,
	"checkbox": `OFwu1`
};
export default ___CSS_LOADER_EXPORT___;
