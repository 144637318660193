// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LlTll{margin-left:1.8rem}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldChildren/FormFieldChildren.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA","sourcesContent":[".container {\n  margin-left: 1.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LlTll`
};
export default ___CSS_LOADER_EXPORT___;
