import { WppModal, WppButton } from '@platform-ui-kit/components-library-react'

import styles from 'app/components/confirmation/Confirmation.module.scss'

interface IConfirmationProps {
  /** If modal is open */
  isOpen: boolean
  /** Handle Save Event */
  handlePrimaryAction: () => void
  /** Handle Cancel Event */
  handleSecondaryAction: () => void
  /** Modal title */
  title: string
  /** Modal body */
  body: string
  /** Primary button text */
  btnPrimaryText?: string

  /** Secondary button text */
  btnSecondaryText?: string
}

/**
 * Confirmation Modal component
 * @param {object} props
 * @param {isOpen} props.isOpen
 * @param {handlePrimaryAction} props.handlePrimaryAction
 * @param {handleSecondaryAction} props.handleSecondaryAction
 * @param {title} props.title
 * @param {body} props.body
 */
const Confirmation: React.FC<IConfirmationProps> = ({
  title,
  body,
  isOpen,
  handlePrimaryAction,
  handleSecondaryAction,
  btnPrimaryText,
  btnSecondaryText,
}: IConfirmationProps): React.ReactElement => (
  <WppModal open={isOpen}>
    <h3 slot="header">{title}</h3>
    <p slot="body">{body}</p>
    <div slot="actions" className={styles.actions}>
      <WppButton className={styles.btnSave} variant="primary" size="s" onClick={handlePrimaryAction}>
        {btnPrimaryText || 'Save'}
      </WppButton>
      <WppButton variant="secondary" size="s" onClick={handleSecondaryAction}>
        {btnSecondaryText || 'Cancel'}
      </WppButton>
    </div>
  </WppModal>
)

export default Confirmation
