import { WppButton, WppFileUpload } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import commonStyles from 'app/components/form/Form.module.scss'
import styles from 'app/components/form/formField/formFieldFileUpload/FormFieldFileUpload.module.scss'
import IField from 'interfaces/field/IField'
import IFile from 'interfaces/file/IFile'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import FileHelper from 'utils/file/FileHelper'

interface IFormFieldFileUploadProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create label for the field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldFileUpload: React.FC<IFormFieldFileUploadProps> = ({
  field,
  handleChange,
}: IFormFieldFileUploadProps): React.ReactElement => {
  const { messageType, errorMessage, fieldConfig, value, disabled } = field
  const { file, multi } = fieldConfig

  const handleFileUploadChange = async (event: CustomEvent) => {
    const fileHelper = new FileHelper('')

    handleChange({
      field,
      value: fileHelper.getInputFiles(event, value),
      hasError: event.detail.hasError,
    })
  }

  return (
    <>
      {file?.templateTitle && file?.templateUrl && (
        <div className={styles.downloadContainer}>
          <WppButton
            id="btn-download-template"
            onClick={() => window.open(file.templateUrl, '_blank')}
            className={styles.downloadTemplate}
          >
            {file?.templateTitle}
          </WppButton>
        </div>
      )}

      <WppFileUpload
        onWppChange={handleFileUploadChange}
        size={file?.maxSize}
        value={value.map((data: IFile) =>
          !!data.id
            ? data.file
            : { name: data.file.name, size: data.file.size, type: data.file.type, url: data.file.url, disabled },
        )}
        multiple={multi}
        disabled={disabled}
        acceptConfig={file?.accept}
        className={classNames(
          _.isEqual(messageType, 'error') ? commonStyles.errorMessage : commonStyles.customStyleDefaultMessage,
          styles.fileUpload,
        )}
        onWppFileUploadItemClick={event => {
          const UploadedFiles = value.filter((file: IFile) => !!file.id)
          const SelectedFile: IFile | undefined = UploadedFiles.find(({ file }: IFile) =>
            _.isEqual(file.name, event.detail.name),
          )
          if (!!SelectedFile) {
            window.open(SelectedFile?.file.url, '_blank')
          }
        }}
        message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      />
    </>
  )
}

export default FormFieldFileUpload
