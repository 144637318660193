import { WppIconInfo, WppTooltip } from '@platform-ui-kit/components-library-react'
import * as _ from 'lodash'
import React, { useCallback, useState } from 'react'

import styles from 'app/components/form/formField/formFieldTooltip/FormFieldTooltip.module.scss'
import Help from 'app/components/help'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldTooltipProps {
  /** IHelpModal interface */
  helpModal?: IHelpModal
  tooltip: string
}

/**
 * Create help content with side panel
 * @param {object} props
 * @param {IHelpModal} props.helpModal
 * @param {string} props.tooltip
 */
const FormFieldTooltip: React.FC<IFormFieldTooltipProps> = ({
  helpModal,
  tooltip,
}: IFormFieldTooltipProps): React.ReactElement => {
  const [isModalOpen, setModalStatus] = useState<boolean>(false)

  const handleModalAction = useCallback((): void => {
    if (!!helpModal) {
      setModalStatus(!isModalOpen)
    }
  }, [isModalOpen, helpModal])

  if (_.isEmpty(tooltip) && !helpModal) {
    return <></>
  }

  let infoIcon = <WppIconInfo className={styles.infoIcon} />

  if (!_.isEmpty(tooltip)) {
    infoIcon = <WppTooltip text={tooltip || ''}>{infoIcon}</WppTooltip>
  }

  return (
    <>
      {!!helpModal && (
        <Help
          title={helpModal.title}
          content={helpModal.content}
          isModalOpen={isModalOpen}
          handleModalAction={handleModalAction}
        />
      )}
      <button
        onClick={event => {
          event.stopPropagation()
          handleModalAction()
        }}
      >
        {infoIcon}
      </button>
    </>
  )
}

export default FormFieldTooltip
