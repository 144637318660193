import { useOs } from '@wpp-open/react'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import styles from 'app/App.module.scss'
import QuestionnaireApplication from 'pages/Main'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <Routes>
          <Route index element={<QuestionnaireApplication />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}
