import { WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'pages/appError/ApplicationError.module.scss'

/**
 * ApplicationError
 */
const ApplicationError: React.FC = (): React.ReactElement => {
  return (
    <WppGrid container className={styles.errorContainer} rowSpacing={2}>
      <WppGrid item all={24}>
        <WppTypography type="4xl-display">Application Error</WppTypography>
        <br />
        <WppTypography type="m-body">
          Please try again later or contact customer support if the problem persists.
        </WppTypography>
      </WppGrid>
    </WppGrid>
  )
}

export default ApplicationError
