import { WppStep } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'app/components/category/Category.module.scss'
import ICategory from 'interfaces/ICategory'

interface ICategoryProps {
  /** Category */
  category: ICategory
}

/**
 * Category component
 * @param {object} props Component props
 * @param {ICategory} props.category Category
 */
const Category: React.FC<ICategoryProps> = ({ category }: ICategoryProps): React.ReactElement => {
  return (
    <WppStep error={!category.isValid} className={styles.customStyleStep} key={category.id}>
      <p slot="label">{category.name}</p>
    </WppStep>
  )
}

export default Category
