import { WppRadio } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import FormFieldLabel from 'app/components/form/formField/formFieldLabel'
import styles from 'app/components/form/formField/formFieldRadio/FormFieldRadio.module.scss'
import { LabelType, Orientation } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IOption from 'interfaces/IOption'

interface IFormFieldRadioProps {
  /** IField interface */
  field: IField
  /** IOption interface */
  option: IOption
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create radio field
 * @param {object} props
 * @param {IField} props.field
 * @param {IOption} props.option
 * @param {Function} props.handleChange
 */
const Radio: React.FC<IFormFieldRadioProps> = ({
  field,
  option,
  handleChange,
}: IFormFieldRadioProps): React.ReactElement => {
  const { disabled, fieldConfig, value } = field
  const { text, orientation } = fieldConfig

  return (
    <div
      className={classNames(styles.radio, {
        [styles.displayRadioVertical]: _.isEqual(orientation, Orientation.VERTICAL),
        [styles.displayRadioHorizontal]: _.isEqual(orientation, Orientation.HORIZONTAL),
      })}
    >
      <WppRadio
        required={true}
        name={text}
        disabled={disabled}
        value={option.id}
        checked={_.isEqual(option.id, value)}
        labelConfig={{
          text: '',
        }}
        className={styles.radioButton}
      />
      <FormFieldLabel
        id={`${field.id}-${_.toString(option.id).split(' ').join('_')}`}
        type={LabelType.BODY}
        text={option.label}
        helpModal={option.help_model}
        disabled={field.disabled}
        handleChange={() => {
          handleChange({
            field,
            value: option.id,
          })
        }}
      />
    </div>
  )
}

export default Radio
