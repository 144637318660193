import {
  WppSelect,
  WppListItem,
  WppGrid,
  WppTypography,
  WppButton,
  WppLabel,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'

import Confirmation from 'app/components/confirmation'
import Spinner from 'app/components/spinner/Spinner'
import Toast from 'app/components/toast'
import config, { API_TYPES, TOAST_DURATION } from 'config/constants'
import { ToastMessageType } from 'config/enums'
import useToast from 'hooks'
import IOption from 'interfaces/IOption'
import AxiosService from 'lib/AxiosService'
import ApplicationError from 'pages/appError/ApplicationError'
import styles from 'pages/Main.module.scss'
import Questionnaire from 'pages/questionnaire/Questionnaire'
import SubmitHelper from 'utils/form/SubmitHelper'
import SharedHelper from 'utils/SharedHelper'

/**
 * Application
 */
const Application: React.FC = (): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const [showQuestionnaireSelection, setShowQuestionnaireSelection] = useState<boolean>(false)
  const [questionnaires, setQuestionnaires] = useState<IOption[]>([])
  const [initLoading, setInitLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [questionnaire, setQuestionnaire] = useState<string | null>(null)
  const { showToast } = useToast()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const OS_CONTEXT: any = osContext
  const TENANT_ID = osContext.tenant.id
  const PROJECT_ID = OS_CONTEXT.project.id
  const ITEM_ID = OS_CONTEXT.project.itemId

  const onAppSelect = (event: CustomEvent) => {
    setQuestionnaire(event.detail.value)
  }

  const onSaveProjectQuestionnaire = async () => {
    setOpenModal(false)
    setLoading(true)
    try {
      if (!questionnaire) {
        return
      }
      const helper = new SubmitHelper()

      await helper.onSaveProjectQuestionnaire(osApi.getAccessToken(), TENANT_ID, {
        projectId: PROJECT_ID,
        questionnaireId: questionnaire,
        tenantId: osContext.tenant.id,
        itemId: ITEM_ID,
      })
      setShowQuestionnaireSelection(false)
      setLoading(false)
    } catch {
      setLoading(false)
      showToast({
        header: 'Oops! Something went wrong',
        message:
          'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        type: ToastMessageType.ERROR,
        duration: TOAST_DURATION,
      })
    }
  }

  const getQuestionnaireList = useCallback(async () => {
    const axiosService = new AxiosService(osApi.getAccessToken())
    const sharedHelper = new SharedHelper()
    const questionnaires: any = await axiosService.get(
      `${config[API_TYPES.QUESTIONNAIRE_API]}/questionnaire/${TENANT_ID}`,
      TENANT_ID,
    )
    setQuestionnaires(_.sortBy(sharedHelper.getOptions(questionnaires.data, 'id', 'name'), ['label'], ['asc']))
    setShowQuestionnaireSelection(true)
    setInitLoading(false)
  }, [osApi, TENANT_ID])

  const initLoad = useCallback(async () => {
    try {
      setInitLoading(true)
      const axiosService = new AxiosService(osApi.getAccessToken())

      let Questionnaire: any = {
        data: '',
      }
      try {
        Questionnaire = await axiosService.get(
          `${config[API_TYPES.QUESTIONNAIRE_API]}/project_questionnaire?project_id=${PROJECT_ID}&tenant_id=${
            osContext.tenant.id
          }&item_id=${ITEM_ID}`,
          TENANT_ID,
        )
      } catch (err: any) {
        if (_.isEqual(err.response.status, 404)) {
          await getQuestionnaireList()
          return
        }
        setError(true)
        return
      }

      setShowQuestionnaireSelection(_.isEmpty(Questionnaire.data))

      if (_.isEmpty(Questionnaire.data)) {
        await getQuestionnaireList()
        return
      }
      setQuestionnaire(Questionnaire.data.questionnaireId)
      setInitLoading(false)
    } catch {
      setError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osApi, osContext.tenant.id, PROJECT_ID, getQuestionnaireList, ITEM_ID])

  useEffect(() => {
    initLoad()
  }, [initLoad])

  if (error) {
    return <ApplicationError />
  }

  return (
    <>
      <Confirmation
        title="Save this from?"
        body="Once you select a form and save, your choice will be final and cannot be changed. Please make sure to choose the correct form for your project"
        isOpen={openModal}
        handlePrimaryAction={onSaveProjectQuestionnaire}
        handleSecondaryAction={() => setOpenModal(false)}
      />
      {(initLoading || loading) && <Spinner />}

      {!initLoading && showQuestionnaireSelection && (
        <WppGrid container className={styles.layout} rowSpacing={2}>
          <WppGrid item all={24} className={styles.layoutHeader}>
            <WppTypography tag="h1" type="2xl-heading">
              Project Requirements Form
            </WppTypography>
          </WppGrid>
          <WppGrid className={styles.layoutForm} item all={24}>
            <WppGrid container className={styles.form}>
              <WppGrid item all={8}>
                <WppTypography className={styles.name} tag="h2" type="xl-heading">
                  Select the form for your project
                </WppTypography>
                <p className={styles.description}>
                  Choose the appropriate form for your project from the options below
                </p>
                <WppLabel className={styles.label} config={{ text: 'Select form' }} typography="s-strong" />

                <WppSelect onWppChange={onAppSelect} placeholder="Select form of project" value={questionnaire}>
                  {questionnaires.map((option: IOption) => (
                    <WppListItem value={option.id} key={option.id}>
                      <p slot="label">{option.label}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
                <WppButton
                  disabled={!questionnaire}
                  id="btn-save"
                  onClick={() => {
                    setOpenModal(true)
                  }}
                  className={styles.btnSave}
                >
                  Save
                </WppButton>
              </WppGrid>
            </WppGrid>
          </WppGrid>
        </WppGrid>
      )}
      {!initLoading && !!questionnaire && !showQuestionnaireSelection && (
        <Questionnaire projectId={PROJECT_ID} questionnaireId={questionnaire} itemId={ITEM_ID} />
      )}
      <Toast />
    </>
  )
}

export default Application
