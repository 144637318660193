import _ from 'lodash'

import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import IApp from 'interfaces/IApp'
import ICategory from 'interfaces/ICategory'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IForm from 'interfaces/IForm'
import IOption from 'interfaces/IOption'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'
import SharedHelper from 'utils/SharedHelper'

export default class FormValidation {
  private formFieldValidation: FormFieldValidation
  private formFieldHelper: FormFieldHelper
  private sharedHelper: SharedHelper

  /**
   *  Creates an instance of FormValidation
   */
  constructor() {
    this.formFieldValidation = new FormFieldValidation()
    this.formFieldHelper = new FormFieldHelper()
    this.sharedHelper = new SharedHelper()
  }

  /**
   * Validate app
   * @param {IApp} app
   * @returns {IApp}
   */
  validateApp(app: IApp): IApp {
    let isAppValid = true
    const Categories = app.categories.map((category: ICategory) => {
      let isValidCategory = true
      let forms: IForm[] = []
      category.forms.forEach((form: IForm) => {
        let fields: IField[] = form.fields.map((field: IField) => {
          const IsValidField = this.formFieldValidation.validate(field, field.value)
          isValidCategory = isValidCategory && IsValidField
          return {
            ...field,
            touched: false,
            isValid: IsValidField,
          }
        })
        forms.push({
          ...form,
          fields,
        })
      })
      isAppValid = isAppValid && isValidCategory

      return {
        ...category,
        forms,
        isValid: true,
      }
    })

    return {
      ...app,
      categories: Categories,
    }
  }

  /**
   * Validate category forms
   * @param {ICategory[]} categories
   * @returns {ICategory[]}
   */
  validateCategories(categories: ICategory[]): ICategory[] {
    return categories.map((category: ICategory) => {
      let isValid = true

      category.forms.forEach((form: IForm) => {
        form.fields.forEach((field: IField) => {
          isValid = isValid && field.isValid
          field.children.forEach((childField: IField) => {
            isValid = isValid && childField.isValid
          })
        })
      })

      return {
        ...category,
        isValid,
      }
    })
  }

  /**
   * If child is visible based on parent field value
   * @param {IField} parent
   * @param {IField} child
   * @returns {boolean}
   */
  isChildVisible(parent: IField, child: IField): boolean {
    if (_.isEqual(parent.type, FieldType.CHECKBOX)) {
      const Values = parent.value.map((option: IOption) => _.toString(option.id))
      return this.sharedHelper.findAnyArrayValueInTargetArray(child.visibleOnValue, Values)
    }
    return this.sharedHelper.findAnyArrayValueInTargetArray(child.visibleOnValue, [_.toString(parent.value)])
  }

  /**
   * Validate Child Field
   * @param {IField} parentField
   * @param {IField} childField
   * @returns {IField}
   */
  validateChild(parentField: IField, childField: IField): IField {
    let isValid = this.formFieldValidation.validate(childField, childField.value)

    isValid = this.isChildVisible(parentField, childField) ? isValid : true

    return {
      ...childField,
      isValid,
      isHidden: !this.isChildVisible(parentField, childField),
    }
  }

  /**
   * Validate Dependent Children
   * @param {ICategory[]} categories
   * @param {IFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  validateDependentField(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return categories.map((category: ICategory) => ({
      ...category,
      forms: category.forms.map((form: IForm) => ({
        ...form,
        fields: form.fields.map((field: IField) => {
          if (_.isEqual(field.id, event.field.id)) {
            const VisibleOn = field.visibleOn || null

            if (!!VisibleOn) {
              const ParentField = this.formFieldHelper.findFieldByFormFieldId(categories, VisibleOn)
              field = !ParentField ? field : this.validateChild(ParentField, field)
            }
            field.children = field.children.map((childField: IField) => {
              return this.validateChild(field, childField)
            })
            return field
          }
          return field
        }),
      })),
    }))
  }

  /**
   * Validate category forms
   * @param {ICategory[]} categories
   * @returns boolean
   */
  validateForms(categories: ICategory[]): boolean {
    let isValid = true

    categories.forEach((category: ICategory) => {
      isValid = isValid && category.isValid
    })

    return isValid
  }
}
