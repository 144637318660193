import * as _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import FormFieldBuilder from 'app/components/form/formBuilder/formFieldBuilder'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'
import IForm from 'interfaces/IForm'

interface IFormBuilderProps {
  /** IForm[] */
  forms: IForm[]
  /** handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build form
 * @param {object} props Component props
 * @param {IForm} props.form Form object
 * @param {function} props.handleChange handle input field change event
 */
const FormBuilder: React.FC<IFormBuilderProps> = ({ forms, handleChange }: IFormBuilderProps): React.ReactElement => {
  return (
    <>
      {forms.map((form: IForm) => (
        <span key={form.id}>
          {!_.isEmpty(form.name) && (
            <div className={styles.formName}>
              {form.name}
              <div className={styles.tooltipIcon}>
                <FormFieldTooltip helpModal={form.helpModal} tooltip={form.tooltip || ''} />
              </div>
            </div>
          )}

          {form.fields.map((field: IField) => {
            return <FormFieldBuilder key={field.id} field={field} handleChange={handleChange} />
          })}
        </span>
      ))}
    </>
  )
}

export default FormBuilder
