import { InputChangeEventDetail, InputTypes } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppInput } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/IFieldChangeEvent'

interface IFormFieldTextInputProps {
  /** IField interface */
  field: IField
  /** Input type i.e number or Text*/
  inputType: InputTypes
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create text input field
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.inputType
 * @param {Function} props.handleChange
 */
const FormFieldTextInput: React.FC<IFormFieldTextInputProps> = ({
  field,
  inputType,
  handleChange,
}: IFormFieldTextInputProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field

  const { placeholder } = fieldConfig

  return (
    <WppInput
      placeholder={placeholder}
      value={value}
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) =>
        handleChange({
          field,
          value: event.detail.value,
        })
      }
      type={inputType}
      id={`textinput-${field.id}`}
      disabled={disabled}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
    />
  )
}

export default FormFieldTextInput
