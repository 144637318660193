// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.km78l{background:#fff;box-shadow:0px 0px 1px rgba(52,58,63,.1),0px 1px 5px rgba(52,58,63,.1);border-radius:8px;padding:24px 32px}.km78l .hgI2y{margin-bottom:.2rem}.km78l .hgI2y .aI1BV{font-weight:bold}.jxxfQ{font-size:1.25rem;font-weight:600}.Sn7bw{font-size:1rem;font-weight:600;margin-bottom:-0.5rem;margin-top:2rem;color:#4d5358;display:flex;align-items:center}.Sn7bw ._4Sz5R{margin-top:5px}.TGQCa{margin-top:1rem;line-height:1.375rem;font-size:.875rem}.TGQCa ul,.TGQCa ol{list-style:inherit;margin-left:2rem}.vitcJ::part(message){color:#4d5358}`, "",{"version":3,"sources":["webpack://./src/app/components/form/Form.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,sEAAA,CACA,iBAAA,CACA,iBAAA,CAEA,cACE,mBAAA,CACA,qBACE,gBAAA,CAKN,OACE,iBAAA,CACA,eAAA,CAGF,OACE,cAAA,CACA,eAAA,CACA,qBAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,eACE,cAAA,CAGJ,OACE,eAAA,CACA,oBAAA,CACA,iBAAA,CAEA,oBAEE,kBAAA,CACA,gBAAA,CAKF,sBACE,aAAA","sourcesContent":[".container {\n  background: #ffffff;\n  box-shadow: 0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1);\n  border-radius: 8px;\n  padding: 24px 32px;\n\n  .completionRate {\n    margin-bottom: 0.2rem;\n    .completionNumber {\n      font-weight: bold;\n    }\n  }\n}\n\n.categoryName {\n  font-size: 1.25rem;\n  font-weight: 600;\n}\n\n.formName {\n  font-size: 1rem;\n  font-weight: 600;\n  margin-bottom: -0.5rem;\n  margin-top: 2rem;\n  color: #4d5358;\n  display: flex;\n  align-items: center;\n  .tooltipIcon {\n    margin-top: 5px;\n  }\n}\n.description {\n  margin-top: 1rem;\n  line-height: 1.375rem;\n  font-size: 0.875rem;\n\n  & ul,\n  ol {\n    list-style: inherit;\n    margin-left: 2rem;\n  }\n}\n\n.customStyleDefaultMessage {\n  &::part(message) {\n    color: #4d5358;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `km78l`,
	"completionRate": `hgI2y`,
	"completionNumber": `aI1BV`,
	"categoryName": `jxxfQ`,
	"formName": `Sn7bw`,
	"tooltipIcon": `_4Sz5R`,
	"description": `TGQCa`,
	"customStyleDefaultMessage": `vitcJ`
};
export default ___CSS_LOADER_EXPORT___;
