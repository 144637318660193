import { WppDivider, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import React from 'react'

import styles from 'app/templates/IdeaBrief/IdeaBrief.module.scss'
import IApp from 'interfaces/IApp'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

interface IIdeaBriefSummaryTemplateProps {
  /** ICategory interface */
  app: IApp
  /** Check if the form should be empty */
  isEmpty?: boolean
}

const formFieldHelper = new FormFieldHelper()

/**
 * Display form
 * @param {object} props
 * @param {ICategory} props.category
 */
const IdeaBriefSummaryTemplate: React.FC<IIdeaBriefSummaryTemplateProps> = ({
  app,
  isEmpty,
}: IIdeaBriefSummaryTemplateProps): React.ReactElement => {
  const fieldsData = formFieldHelper.getFormFieldsObject(app.categories, isEmpty)

  return (
    <>
      <WppGrid fullWidth container>
        <WppGrid item all={24} className={styles.appContainer}>
          <WppTypography tag="p" type="xl-heading" className={styles.appName}>
            {app.appName}
          </WppTypography>
          <div className={styles.appBorder} />
          <WppTypography tag="p" type="xl-heading">
            THE ASK : {fieldsData['the ask']}
          </WppTypography>
        </WppGrid>
      </WppGrid>
      <div className={styles.headerContainer}>
        <div className={styles.headerContainerItem}>
          <div className={styles.theAskContainer}>
            <div className={styles.theAskFieldContainer}>
              <WppTypography tag="p" type="m-strong" className={styles.theAskFieldLabel}>
                Brand
              </WppTypography>
              <WppTypography tag="p" type="m-strong" className={styles.theAskFieldValue}>
                {fieldsData['brand']}
              </WppTypography>
            </div>
            <div className={styles.theAskFieldContainer}>
              <WppTypography tag="p" type="m-strong" className={styles.theAskFieldLabel}>
                Market
              </WppTypography>
              <WppTypography tag="p" type="m-strong" className={styles.theAskFieldValue}>
                {fieldsData['market']}
              </WppTypography>
            </div>
            <div className={styles.theAskFieldContainer}>
              <WppTypography
                tag="p"
                type="m-strong"
                className={`${styles.theAskFieldLabel} ${styles.theAskFieldLastChild}`}
              >
                Date
              </WppTypography>
              <WppTypography
                tag="p"
                type="m-strong"
                className={`${styles.theAskFieldValue} ${styles.theAskFieldLastChild}`}
              >
                {fieldsData['date']}
              </WppTypography>
            </div>
          </div>
        </div>
        <div className={styles.headerContainerItem}>
          <div>
            <WppTypography tag="p" type="l-strong">
              PERSISTENT PLATFORM / EXPERIENCE PROGRAM
            </WppTypography>
            <WppTypography tag="p" type="l-strong" className={styles.persistentProgram}>
              {fieldsData['persistent platform / experience program']}
            </WppTypography>
          </div>
        </div>
      </div>
      <WppGrid container fullWidth className={styles.categoriesSection}>
        <WppGrid item all={8} className={styles.categoriesItem}>
          <WppTypography tag="p" type="m-strong" className={classNames(styles.categoryName, styles.theSetupHypothesis)}>
            THE SETUP
          </WppTypography>
          <div className={styles.categoryContainer}>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                THE PROBLEM:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['the problem']}
              </WppTypography>
            </div>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                WHO ARE WE ENGAGING WITH:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['who are we engaging with']}
              </WppTypography>
            </div>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                THE INSIGHTS:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['the insights']}
              </WppTypography>
            </div>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                PROPELLING QUESTION:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['propelling question']}
              </WppTypography>
            </div>
          </div>
        </WppGrid>
        <WppGrid item all={8} className={styles.categoriesItem}>
          <WppTypography tag="p" type="m-strong" className={classNames(styles.categoryName, styles.theSetupHypothesis)}>
            THE HYPOTHESIS
          </WppTypography>
          <div className={styles.categoryContainer}>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                WAYS IN:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerWayInValue}>
                {fieldsData['ways in']}
              </WppTypography>
            </div>
            <div className={styles.waysInFieldContainer}>
              <div className={styles.waysInFieldDisplayContainer}>
                <WppTypography
                  tag="p"
                  type="m-strong"
                  className={classNames(styles.fieldContainerLabel, styles.watsInFieldLabel)}
                >
                  WAY IN #1:
                </WppTypography>
                <WppDivider className={styles.fullWidth} />
              </div>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerWayInValue}>
                {fieldsData['way in #1']}
              </WppTypography>
            </div>
            <div className={styles.waysInFieldContainer}>
              <div className={styles.waysInFieldDisplayContainer}>
                <WppTypography
                  tag="p"
                  type="m-strong"
                  className={classNames(styles.fieldContainerLabel, styles.watsInFieldLabel)}
                >
                  WAY IN #2:
                </WppTypography>
                <WppDivider className={styles.fullWidth} />
              </div>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerWayInValue}>
                {fieldsData['way in #2']}
              </WppTypography>
            </div>
            <div className={styles.waysInFieldContainer}>
              <div className={styles.waysInFieldDisplayContainer}>
                <WppTypography
                  tag="p"
                  type="m-strong"
                  className={classNames(styles.fieldContainerLabel, styles.watsInFieldLabel)}
                >
                  WAY IN #3:
                </WppTypography>
                <WppDivider className={styles.fullWidth} />
              </div>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerWayInValue}>
                {fieldsData['way in #3']}
              </WppTypography>
            </div>
          </div>
        </WppGrid>
        <WppGrid item all={8} className={styles.categoriesItem}>
          <WppTypography tag="p" type="m-strong" className={classNames(styles.categoryName, styles.theExpectedOutcome)}>
            THE EXPECTED OUTCOME
          </WppTypography>
          <div className={styles.categoryContainer}>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                SETTING UP EXPECTATIONS:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['setting up expectations']}
              </WppTypography>
            </div>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                THE DREAM:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['the dream']}
              </WppTypography>
            </div>
            <div>
              <WppTypography tag="p" type="m-strong" className={styles.fieldContainerLabel}>
                THE BUSINESS RESULTS:
              </WppTypography>
              <WppTypography tag="p" type="m-body" className={styles.fieldContainerValue}>
                {fieldsData['the business results']}
              </WppTypography>
            </div>
          </div>
        </WppGrid>
      </WppGrid>
    </>
  )
}

export default IdeaBriefSummaryTemplate
